<template></template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  async mounted() {
    this.$vs.loading();
    await this.timeout(1000);
    if(this.isLogged == true && this.user != null){
      this.$router.replace({name: 'home'});
      this.$vs.loading.close();
    }
    else {
      this.$vs.loading.close();
    }
  },
  methods: {
    ...mapActions('auth', ['getUserInfo']),
    ...mapMutations('auth', ['setStateAccessToken']),
    timeout(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  computed: {
    ...mapState("auth", ["user", "isLogged"]),
  },
  watch: {
    isLogged(val){
      if(val){
        // esperar a que se obtengan los datos de usuario para ir al inicio
        this.$router.replace({name: 'home'});
        this.$vs.loading.close();
      }
    }
  }
};
</script>